import addTranslation from '~ui/translations/addTranslation';

export default addTranslation({
  id: 'CloudOpsServiceForm',
  sv: {
    title: 'Välj CloudOps Tjänst',
    buttonText: 'Välj',
    back: 'Till alla tjänster',
    for: 'För',
    operationHours: 'Aktiveringsintervall',
    month: 'Månad',
    btnCREATE: 'Aktivera',
    btnEDIT: 'Uppdatera',

    success: 'Uppdaterats',
    fail: 'Kunde inte uppdatera',
  },
  en: {
    title: 'Select CloudOps Service',
    buttonText: 'Select',
    back: 'Back to services',
    for: 'For',
    operationHours: 'Activation interval',
    month: 'Month',
    btnCREATE: 'Activate',
    btnEDIT: 'Update',

    success: 'Updated',
    fail: 'Could not update',
  },
});
